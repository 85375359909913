import { Identifiable, Nameable } from './core.model';
import { Facility } from './facility.model';

export interface GeofenceType extends Identifiable, Nameable {
    color: string;
    category: GeofenceTypeCategory;
}

export interface GeofenceTypeFacilities extends GeofenceType {
    facilities?: Facility[];
}

export enum GeofenceTypeCategory {
    WorkingArea = 'working-area',
    BreakArea = 'break-area',
    TravelArea = 'travel-area',
}
