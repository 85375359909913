import { HeaderBarOptions } from 'components/table/header-bar/header-bar.model';
import { FilterFolder, FolderType } from '../models/folder.model';
import { PermissionAction } from '../permissions/permissions.model';
import { css } from '../utils/css';
import { FEATURE_ICONS } from '../utils/feature.icons';
import { Company } from './company.model';
import { Craft } from './craft.model';
import { FormAction } from './forms.model';
import { AppIcon } from './icon.model';
import { Tag } from './tag.model';
import { HttpError } from '@weavix/models/src/api/http-response';

export interface TableOptions<TRowData = any> {
    title: {
        string: string;
        centered?: boolean;
        fontSize?: number;
        paddingLeft?: number;
    };
    hasDefaultSort?: boolean;
    translate?: boolean;
    rowCount?: boolean;
    back?: boolean | (() => void);
    keyCol: keyof TRowData;
    columns: TableColumn<TRowData>[];
    columnGroups?: TableColumnGroup[];
    rowEdits: RowEdit[];
    canAdd?: boolean;
    canImport?: boolean;
    headerOptions?: TableHeaderOptions<TRowData>;
    headerBarOptions?: HeaderBarOptions[];
    tablePadding?: number;
    backgroundColor?: string;
    addOptions?: Array<{
        display: string;
        id: string;
        onClick: () => void
    }>;
    externalSearch?: {
        fields?: string[];
    };
    pagination?: {
        initPageSize?: number;
        showMoreSize?: number;
        pageUpdated?: ((shown: number, total: number) => void);
    };
    filters?: TableFilter<TRowData>[];
    select?: {
        selectable?: boolean;
        multiSelect?: boolean;
        showCheckboxes?: boolean;
        showCheckboxesWithEditPermission?: boolean;
        rowsClickable?: boolean; // if you want action on row click
        rowsDblClickable?: boolean;
        checkOnClick?: boolean;
    };
    format?: (row: TRowData) => any;
    bulk?: {
        url: (id: string) => string;
        cache: string;
        customAction?: boolean; // Will emit a bulkMoveEdit event rather than use batch service
    };
    tableEdits?: TableEdit[];
    presetSearchQuery?: string;
    folderType?: FolderType;
    folderRowSuffix?: {
        show: boolean;
        icon?: AppIcon;
    };
    /** Folder edit modal will not show permission selections */
    disableFolderPermissions?: boolean;
    viewPermissionAction?: PermissionAction;
    editPermissionAction?: PermissionAction;

    // for a given row, return what facilities it is part of for permission checks
    facilityIds?: (row: TRowData) => string[];
    facilityId?: string; // if it is a single fixed facilityId use this
    // set to true if the user has to have permissions in all facilities
    allFacilities?: (row: TRowData) => boolean;

    // use this method to manually define whether a row can be edited / viewed instead of the default permission check
    hasPermission?: (action: PermissionAction, row: TRowData) => boolean;

    submitPermissionAction?: PermissionAction;
    routerLink?: ((row: TRowData) => string);
    folderId?: string;
    minHeight?: number;
    deleteMessageKey?: string;
    noData?: {
        canAdd?: boolean;
        icon?: string;
        messageKey?: string;
    };
    hideTableIntro?: boolean;
    hideColumnHeaders?: boolean;
    folderIsLink?: boolean;
    showStickyButtons?: boolean;
    rowsExpandContainer?: boolean;
    csvExport?: boolean;
    canMoveRow?: (row: TRowData) => boolean;
    unavailableRowText?: (row: TRowData) => string;
    locked?: (row: TRowData) => boolean;
    onBack?: () => any;
    importKey?: string;
    duration?: number;
    settingsKey?: string;
    paginate?: {
        enabled: boolean;
        pageSize: number;
        loadMore?: ((limit: number, sortColumn?: TableColumn<TRowData>, direction?: PaginationDirection, reload?: boolean) => any);
        isLazyLoaded?: boolean;
    };
    isSubTable?: boolean;
    columnAlignmentOffset?: number; // if you need to offset the column headers for an icon in a table row
    showHeaderSeparator?: boolean;
    sideBarFilters?: {
        show?: boolean;
    };
    rowIcon?: (row: TRowData) => AppIcon;
    rowBackgroundColor?: ((row: TableRow<TRowData>) => string) | string;
    rowIconFixedWidth?: boolean;
    totalsRowConfig?: { show: boolean; };
    /**
     * Highlighted Rows will have an orange highlight on the left side of the row
     * @param highlightedRows A list of IDs to highlight (via keyCol)
     */
    highlightedRows?: string[];

    /**
     * Unavailable rows are rows that can stil be clicked into and viewed, but are read only.
     * An example is a craft that has sites that a user does not have access to.
     * The user is still able to view and click into the craft, but cannot edit it.
     * @param unavailableRows A list of IDs that are unavailable, as described above (via keyCol)
     */
    unavailableRows?: string[];

    unavailableAdminRows?: string[];

    /**
     * A message to show at the bottom of all of the table rows, such as "no more data"
     */
    endMessageKey?: string;
    endMessageNgStyle?: {[cssPropName: string]: string | number};
}

export interface TableHeaderOptions<TRowData = any> {
    hide?: boolean;
    title?: string;
    isTitleVisible?: boolean;
    isPaginationInfoVisible?: boolean;
    hasColumnGroups?: boolean;
    actions?: TableHeaderAction[];
    reportActions?: TableHeaderReportAction[];
    hasPermission?: (action: PermissionAction, row) => boolean;
    search?: TableHeaderSearch<TRowData>;
    selectable?: boolean;
    bulkEdits?: BulkEdit[];
    folderType?: FolderType;
    editPermissionAction?: PermissionAction;
    facilityId?: string;
    facilityIds?: (row: TRowData) => string[];
    import?: {
        name: string;
        action: () => void;
    };
}

export interface TableHeaderAction {
    name?: string;
    type?: TableHeaderActionType;
    icon?: string;
    disabled?: () => boolean;
    permission?: PermissionAction;
    facilityId?: string;
    multiFacility?: boolean;
    clickAction?: () => void;
}

export interface TableHeaderReportAction {
    name?: string;
    type?: TableHeaderReportActionType;
    icon?: string;
    isShowLabel?: boolean;
    disabled?: () => boolean;
    permission?: PermissionAction;
    facilityId?: string;
}

export interface TableHeaderSearch<TRowData = any> {
    show?: boolean;
    icon?: string;
    placeholder?: string;
    fields?: (string | ((row: TRowData) => any)) [];
    searchAction?: ((columnNames: (string | ((row: TRowData) => any))[], value: string) => any);
}

export interface TableHeaderPaginationInfo {
    shown?: number;
    total?: number;
}


export enum TableHeaderActionType {
    Add = 'add',
    Delete = 'delete',
    Update = 'update',
    Import = 'import',
}

export enum TableHeaderReportActionType {
    Export = 'export',
}

export const actionTypeToTranslationKey: {[key in TableHeaderActionType]?: string} = {
    [TableHeaderActionType.Add]: 'table.header.add',
    [TableHeaderActionType.Delete]: 'table.header.delete',
    [TableHeaderActionType.Update]: 'table.header.update',
    [TableHeaderActionType.Import]: 'table.header.import',
};

export const reportActionTypeToTranslationKey: {[key in TableHeaderReportActionType]?: string} = {
    [TableHeaderReportActionType.Export]: 'table.header.report.export',
};

export interface TableExportData {
    keys?: string[];
    folderKeys?: string[];
    folderUrls?: { [folderKey: string]: string };
}

export interface TableRow<TRowData = any> {
    original: TRowData;
    key: string;
    items: RowItem[];
    selected?: boolean;
    highlighted?: boolean;
    disabled?: boolean;
    /**
     * Marked rows will have the left end highlighted in orange
     */
    marked?: boolean;
    /**
     * Set rowPrefix to true if any rows in a list are marked
     * this keeps spacing consistent
     */
    rowPrefix?: boolean;
    /**
     * Disabled rows are 50% grey, relative to normal table rows
     */
    disabledRowText?: string;
    rowSuffix?: {
        icon: (row: TRowData) => AppIcon;
        text: (row: TRowData) => string;
    };
    edits?: RowEdit[];
    isFolder?: boolean;
    link?: string;
    clickable?: boolean;
    checkbox?: boolean;
    locked?: boolean;
    style?: any;
    subTable?: {
        show: boolean;
        options: TableOptions;
        rows: TRowData[];
    };
    rowHeight?: boolean;
    icon?: AppIcon;
    iconFixedWidth?: boolean;
    class?: string;
    backgroundColor?: ((row: TableRow<TRowData>) => string) | string;
    parentId?: string;
}

export interface RowItemIcon {
    value: string;
    tooltip?: string;
    clickable?: boolean;
    color?: string;
}

export interface RowItem<TRowData = any> {
    value: any;
    tooltip?: any;
    sort?: any;
    colKey: string;
    minWidth?: number;
    maxWidth?: number;
    maxLines?: number;
    class?: string;
    clickable?: boolean;
    pending?: boolean;
    prefix?: AppIcon;
    postfix?: AppIcon;
    rowItemNgStyleCb?: (row: TRowData) => {[key: string]: any};
    rowItemNgStyle?: {[key: string]: any};
    lineExpand?: (row: TRowData) => any;
    centered?: boolean;
}
export interface SubRowItem extends RowItem {
    type: RowItemType; // sub row items have no column mapping, so we set their type (for meow)
}

export interface RowEdit {
    title: string;
    action: RowEditAction;
    icon: string;
    disabled?: boolean | ((row: any) => boolean);
    show?: (row: any) => boolean;
    invisible?: boolean;
}

export enum RowEditAction {
    view = 'view',
    edit = 'edit',
    copy = 'copy',
    delete = 'delete',
    register = 'register',
    print = 'print',
    propertyUpdate = 'propertyUpdate',
    enable = 'enable',
    exclusions = 'exclusions',
    livelook = 'livelook',
    directory = 'directory',
    disable = 'disable',
    fillout = 'fillout',
    generateReport = 'generateReport',
    checkoutEveryone = 'checkoutEveryone',
    redo = 'redo',
    cancel = 'cancel',
    channel = 'channel',
    goToMap = 'goToMap',
    move = 'move',
    logOut = 'logout',
    send = 'send',
    sms = 'sms',
    assign = 'assign',
    unassign = 'unassign',
    remove = 'remove',
    resend = 'resend',
    revoke = 'revoke',
    verify = 'verify',
    viewQr = 'viewQr',
    unblock = 'unblock',
    download = 'download',
}

export const commonRowEdits: { [key: string]: RowEdit} = {
    delete: {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: RowEditAction.delete,
    },
    edit: {
        title: 'generics.edit',
        icon: 'fas fa-pencil-alt',
        action: RowEditAction.edit,
    },
    copy: {
        title: 'generics.copy',
        icon: 'fas fa-copy',
        action: RowEditAction.copy,
    },
};

export interface TableEdit {
    title: string;
    action: TableEditAction;
    icon: string;
    static?: boolean;
    show?: () => boolean;
    disabled?: boolean;
}

export enum TableEditAction {
    import = 'import',
    view = 'view',
    copy = 'copy',
    download = 'download',
}

export interface BulkEdit {
    title: string;
    message?: string;
    action?: BulkEditAction;
    isReportAction?: boolean;
    icon: string;
    permission?: any;
}

export interface MapBulkEdit<E> {
    permission?: PermissionAction;
    title: string;
    action: (entities: E[]) => void;
    icon?: string;
    facilityLookup?: (value: string) => string;
}

export enum BulkEditAction {
    delete = 'delete',
    downloadGuide = 'downloadGuide',
    duplicate = 'duplicate',
    tag = 'tag',
    enable = 'enable',
    disable = 'disable',
    export = 'export',
    send = 'send',
    edit = 'edit',
    move = 'move',
}

export interface BulkEditEvent {
    keys: string[];
    folders: string[];
    nestedKeys?: string[];
    action: BulkEditAction;
    parentFolderId?: string;
    errors: HttpError[];
}

export enum TableFilterCategory {
    People = 'people',
    Companies = 'companies',
    Admin = 'admin',
    GlobalAdmin = 'globalAdmin',
    NotGlobalAdmin = 'notGlobalAdmin',
    Crafts = 'crafts',
    Groups = 'groups',
    Geofences = 'geofences',
    ItemTypes = 'itemTypes',
    Items = 'items',
    Walt = 'walt',
    Wilma = 'wilma',
    Cluster = 'cluster',
    Tags = 'tags',
    TypeBasic = 'typeBasic',
    TypeFull = 'typeFull',
    Languages = 'languages',
    ActiveUsers = 'activeUsers',
    InvitedUsers = 'invitedUsers',
    RequestedUsers = 'requestedUsers',
    BlockedUsers = 'blockedUsers',
    ImportedUsers = 'importedUsers',
    Classifications = 'classifications',
    ConfinedSpace = 'confinedSpace',
    ConfinedSpaceNo = 'confinedSpaceNo',
    ConfinedSpaceYes = 'confinedSpaceYes',
    Sites = 'sites',
    Users = 'users',
    Type = 'type',
    Wisps = 'wisps',
    WispAssigned = 'wispAssigned',
    WispNotAssigned = 'wispNotAssigned',
    Badge = 'badge',
    BadgeAssigned = 'badgeAssigned',
    BadgeNotAssigned = 'badgeNotAssigned',
    Guide = 'guide',
    GuideDownloaded = 'guideDownloaded',
    GuideNotDownloaded = 'guideNotDownloaded',
    Date = 'date',
    HazardLevels = 'hazardLevels',
    Status = 'status',
    Categories = 'categories',
    ResolutionTypes = 'resolutionTypes',
    ReadOnly = 'readOnly',
    ReadOnlyYes = 'readOnlyYes',
    ReadOnlyNo = 'readOnlyNo',
    Roip = 'Roip',
    RoipYes = 'RoipYes',
    RoipNo = 'RoipNo',
    Drc = 'dedicatedRadioChannel',
    DrcYes = 'dedicatedRadioChannelYes',
    DrcNo = 'dedicatedRadioChannelNo',
    Hpc = 'highPriorityChannel',
    HpcYes = 'highPriorityChannelYes',
    HpcNo = 'highPriorityChannelNo',
}

export enum FilterResultType {
    Person = 'person',
    Items = 'items',
    ItemTypes = 'itemTypes',
    Geofence = 'geofence',
    GlobalAdmin = 'globalAdmin',
    NotGlobalAdmin = 'notGlobalAdmin',
    MapView = 'mapView',
    Company = 'company',
    Craft = 'craft',
    Form = 'form',
    Rule = 'rule',
    Channel = 'channel',
    Tag = 'tag',
    Language = 'language',
    Badge = 'badge',
    PermissionGroup = 'permissionGroup',
    Walt = 'walt',
    Wilma = 'wilma',
    ActiveUsers = 'activeUsers',
    InvitedUsers = 'invitedUsers',
    RequestedUsers = 'requestedUsers',
    BlockedUsers = 'blockedUsers',
    ImportedUsers = 'importedUsers',
    Classifications = 'classifications',
    ConfinedSpace = 'confinedSpace',
    ConfinedSpaceNo = 'confinedSpaceNo',
    ConfinedSpaceYes = 'confinedSpaceYes',
    Site = 'site',
    TypeBasic = 'typeBasic',
    TypeFull = 'typeFull',
    WispAssigned = 'wispAssigned',
    WispNotAssigned = 'wispNotAssigned',
    BadgeAssigned = 'badgeAssigned',
    BadgeNotAssigned = 'badgeNotAssigned',
    GuideDownloaded = 'guideDownloaded',
    GuideNotDownloaded = 'guideNotDownloaded',
    Date = 'date',
    HazardLevels = 'hazardLevels',
    Status = 'status',
    Categories = 'categories',
    ResolutionTypes = 'resolutionTypes',
    ReadOnly = 'readOnly',
    ReadOnlyYes = 'readOnlyYes',
    ReadOnlyNo = 'readOnlyNo',
    Roip = 'Roip',
    RoipYes = 'RoipYes',
    RoipNo = 'RoipNo',
    Drc = 'dedicatedRadioChannel',
    DrcYes = 'dedicatedRadioChannelYes',
    DrcNo = 'dedicatedRadioChannelNo',
    Hpc = 'highPriorityChannel',
    HpcYes = 'highPriorityChannelYes',
    HpcNo = 'highPriorityChannelNo',
}

export const CATEGORY_PARENT_MAP: {[key in TableFilterCategory]?: TableFilterCategory} = {
    [TableFilterCategory.ActiveUsers]: TableFilterCategory.Users,
    [TableFilterCategory.InvitedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.RequestedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.BlockedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.ImportedUsers]: TableFilterCategory.Users,
    [TableFilterCategory.TypeBasic]: TableFilterCategory.Type,
    [TableFilterCategory.TypeFull]: TableFilterCategory.Type,
    [TableFilterCategory.WispAssigned]: TableFilterCategory.Wisps,
    [TableFilterCategory.WispNotAssigned]: TableFilterCategory.Wisps,
    [TableFilterCategory.BadgeAssigned]: TableFilterCategory.Badge,
    [TableFilterCategory.BadgeNotAssigned]: TableFilterCategory.Badge,
    [TableFilterCategory.GuideDownloaded]: TableFilterCategory.Guide,
    [TableFilterCategory.GuideNotDownloaded]: TableFilterCategory.Guide,
    [TableFilterCategory.GlobalAdmin]: TableFilterCategory.Admin,
    [TableFilterCategory.NotGlobalAdmin]: TableFilterCategory.Admin,
    [TableFilterCategory.ReadOnlyYes]: TableFilterCategory.ReadOnly,
    [TableFilterCategory.ReadOnlyNo]: TableFilterCategory.ReadOnly,
    [TableFilterCategory.ConfinedSpaceYes]: TableFilterCategory.ConfinedSpace,
    [TableFilterCategory.ConfinedSpaceNo]: TableFilterCategory.ConfinedSpace,
    [TableFilterCategory.RoipYes]: TableFilterCategory.Roip,
    [TableFilterCategory.RoipNo]: TableFilterCategory.Roip,
    [TableFilterCategory.DrcYes]: TableFilterCategory.Drc,
    [TableFilterCategory.DrcNo]: TableFilterCategory.Drc,
    [TableFilterCategory.HpcYes]: TableFilterCategory.Hpc,
    [TableFilterCategory.HpcNo]: TableFilterCategory.Hpc,
};

export interface TableFilter<TRowData = any> {
    title: string;
    selected: boolean;
    key?: any;
    filterFn(row: TableRow<TRowData>): boolean;
}

export interface TableFilterResult {
    name: string;
    icon?: string | AppIcon;
    key: string;
    category: TableFilterCategory;
    type: FilterResultType;
    selected?: boolean;
    hidden?: boolean;
    setSelected?: (this: TableFilterResult, selected: boolean, original?: boolean, click?: boolean) => any;
    disabled?: (this: TableFilterResult) => boolean;
    appendixText?: (this: TableFilterResult) => string;
    appendixIcon?: AppIcon;
    childCount?: number;
    data?: Company | Craft | Tag;
    skipSortChildren?: boolean;
    children?: {[key: string]: TableFilterResult};
    entities?: {[key: string]: TableFilterResult}; // entities you want to keep track of within category (i.e. people in company)
    filters?: {[key in TableFilterCategory]?: {[id: string]: TableFilterResult}};
    customClickAction?: () => void;
    multiselect?: boolean;
}

export type TableFilterCategoryResults = {[key in TableFilterCategory]?: {[key: string]: TableFilterResult}};
export type TableFilterCategoryToIdKey = {[key in TableFilterCategory]?: { key: string, collection?: boolean }};

export const filterCategoryToTranslationKey: {[key in TableFilterCategory]?: string} = {
    [TableFilterCategory.Companies]: 'shared.person.company',
    [TableFilterCategory.Admin]: 'person.global-admin',
    [TableFilterCategory.GlobalAdmin]: 'generics.yes',
    [TableFilterCategory.NotGlobalAdmin]: 'generics.no',
    [TableFilterCategory.Crafts]: 'shared.person.craft',
    [TableFilterCategory.Tags]: 'shared.person.tags',
    [TableFilterCategory.Sites]: 'shared.person.sites',
    [TableFilterCategory.Users]: 'shared.person.status',
    [TableFilterCategory.Type]: 'shared.person.type',
    [TableFilterCategory.TypeFull]: 'shared.person.full',
    [TableFilterCategory.TypeBasic]: 'shared.person.basic',
    [TableFilterCategory.Languages]: 'configuration.user.locale',
    [TableFilterCategory.ActiveUsers]: 'shared.person.active',
    [TableFilterCategory.InvitedUsers]: 'shared.person.invited',
    [TableFilterCategory.RequestedUsers]: 'shared.person.requested',
    [TableFilterCategory.BlockedUsers]: 'shared.person.blocked',
    [TableFilterCategory.ImportedUsers]: 'shared.person.imported',
    [TableFilterCategory.Classifications]: 'shared.geofence.classifications',
    [TableFilterCategory.Wisps]: 'shared.person.wisps',
    [TableFilterCategory.WispAssigned]: 'shared.person.assigned',
    [TableFilterCategory.WispNotAssigned]: 'shared.person.not-assigned',
    [TableFilterCategory.Badge]: 'shared.person.badge',
    [TableFilterCategory.BadgeAssigned]: 'shared.person.assigned',
    [TableFilterCategory.BadgeNotAssigned]: 'shared.person.not-assigned',
    [TableFilterCategory.Guide]: 'shared.person.downloadOnboardingGuides',
    [TableFilterCategory.GuideDownloaded]: 'shared.person.downloaded',
    [TableFilterCategory.GuideNotDownloaded]: 'shared.person.not-downloaded',
    [TableFilterCategory.Geofences]: 'shared.geofence.geofence',
    [TableFilterCategory.HazardLevels]: 'shared.safety.hazard-level',
    [TableFilterCategory.Status]: 'shared.safety.status',
    [TableFilterCategory.Categories]: 'shared.safety.categories',
    [TableFilterCategory.ResolutionTypes]: 'shared.safety.resolution-type',
    [TableFilterCategory.ConfinedSpace]: FEATURE_ICONS.confinedSpace.label,
    [TableFilterCategory.ConfinedSpaceYes]: 'shared.generics.yes',
    [TableFilterCategory.ConfinedSpaceNo]: 'shared.generics.no',  
    [TableFilterCategory.Roip]: FEATURE_ICONS.roip.label,
    [TableFilterCategory.RoipYes]: 'shared.generics.yes',
    [TableFilterCategory.RoipNo]: 'shared.generics.no',
    [TableFilterCategory.ReadOnly]: FEATURE_ICONS.readOnly.label,
    [TableFilterCategory.ReadOnlyYes]: 'shared.generics.yes',
    [TableFilterCategory.ReadOnlyNo]: 'shared.generics.no',
    [TableFilterCategory.Drc]: FEATURE_ICONS.drc.label,
    [TableFilterCategory.DrcYes]: 'shared.generics.yes',
    [TableFilterCategory.DrcNo]: 'shared.generics.no',
    [TableFilterCategory.Hpc]: FEATURE_ICONS.hpc.label,
    [TableFilterCategory.HpcYes]: 'shared.generics.yes',
    [TableFilterCategory.HpcNo]: 'shared.generics.no',
};

export const filterCategoryToIcon: {[key in TableFilterCategory]?: string | AppIcon} = {
    [TableFilterCategory.Companies]: 'fas fa-industry ',
    [TableFilterCategory.Admin]: 'fas fa-crown',
    [TableFilterCategory.GlobalAdmin]: 'fa-solid fa-circle-check',
    [TableFilterCategory.NotGlobalAdmin]: 'fa-solid fa-circle-xmark',
    [TableFilterCategory.Crafts]: 'fas fa-tools',
    [TableFilterCategory.Geofences]: 'fas fa-draw-polygon',
    [TableFilterCategory.Tags]: 'fas fa-tags',
    [TableFilterCategory.Sites]: 'fas fa-building',
    [TableFilterCategory.Type]: 'far fa-users',
    [TableFilterCategory.TypeBasic]: 'far fa-user',
    [TableFilterCategory.Languages]: 'far fa-language',
    [TableFilterCategory.TypeFull]: 'fas fa-user',
    [TableFilterCategory.Users]: 'fas fa-wave-pulse',
    [TableFilterCategory.ActiveUsers]: 'fas fa-star',
    [TableFilterCategory.InvitedUsers]: 'fas fa-envelope',
    [TableFilterCategory.RequestedUsers]: 'fa-solid fa-star-of-life',
    [TableFilterCategory.BlockedUsers]: 'fas fa-lock',
    [TableFilterCategory.ImportedUsers]: 'fas fa-clock',
    [TableFilterCategory.Wisps]: FEATURE_ICONS.wisp.icon,
    [TableFilterCategory.WispAssigned]: 'fa-solid fa-circle-check',
    [TableFilterCategory.WispNotAssigned]: 'fa-solid fa-circle-xmark',
    [TableFilterCategory.Badge]: FEATURE_ICONS.badge.icon,
    [TableFilterCategory.BadgeAssigned]: 'fa-solid fa-circle-check',
    [TableFilterCategory.BadgeNotAssigned]: 'fa-solid fa-circle-xmark',
    [TableFilterCategory.Guide]: 'fas fa-file-invoice',
    [TableFilterCategory.GuideDownloaded]: undefined,
    [TableFilterCategory.GuideNotDownloaded]: undefined,
    [TableFilterCategory.HazardLevels]: 'fa-solid fa-triangle-exclamation',
    [TableFilterCategory.Status]: 'fa-solid fa-wave-pulse',
    [TableFilterCategory.Categories]: 'fa-regular fa-layer-group',
    [TableFilterCategory.ResolutionTypes]: 'fa-regular fa-circle-check',
    [TableFilterCategory.Roip]: FEATURE_ICONS.roip.icon.faIcon,
    [TableFilterCategory.ReadOnly]: FEATURE_ICONS.readOnly.icon.faIcon,
    [TableFilterCategory.Drc]: FEATURE_ICONS.drc.icon.faIcon,
    [TableFilterCategory.Hpc]: FEATURE_ICONS.hpc.icon.faIcon,
};

export enum RowItemType {
    avatarPerson = 'avatarPerson',
    checkbox = 'checkbox',
    color = 'color',
    date = 'date',
    duration = 'duration',
    icon = 'icon',
    image = 'image',
    number = 'number',
    text = 'text',
    timeAgo = 'timeAgo',
    time = 'time',
    toggle = 'toggle',
    percent = 'percent',
    folderBack = 'folderBack',
}

export interface TableColumnGroup {
    title: string;
    ids: string[];
}

export interface TableColumn<TRowData = any> {
    id?: string;
    title: string;
    titlePrefixIcon?: AppIcon;
    overridesSelection?: boolean;
    translate?: boolean;
    colKey: string;
    dataColKey?: string;
    type: RowItemType | ((row: TRowData) => RowItemType);
    sort?: TableColumnSort;
    minWidth?: number;
    maxWidth?: number;
    columnHeadTooltip?: string; // tooltip for column header hover
    hideColumnHeadTooltipWhenFit?: boolean;
    preserveCase?: boolean;
    rowItemNgStyle?: {[key: string]: any};
    maxLines?: number;
    rowItemNgStyleCb?: (row: TRowData) => {[key: string]: any};
    lineExpand?(row: TRowData): any;
    total?(row: TRowData): any;
    clickable?(row: TRowData): boolean;
    class?(row: TRowData): string;
    value?(row: TRowData, col?: TableColumn<TRowData>): any;
    tooltip?(row: TRowData): any; // tooltip for column value hover
    pending?(row: TRowData): boolean;
    prefix?(row: TRowData): AppIcon;
    postfix?(row: TRowData): AppIcon;
    icon?(row: TRowData): RowItemIcon;
}

export interface TableColumnSort {
    sortable?: boolean;
    selected?: boolean;
    sortAsc?: boolean;
    fn?(row: any): any;
}

export interface FolderChange {
    folder: FilterFolder;
    action: FormAction;
}

export enum TableSetting {
    folderTree = 'folderTree',
}

export const commonBulkEdits: { [key: string]: BulkEdit } = {
    delete : {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: BulkEditAction.delete,
    },
};

export enum PaginationDirection {
    desc = 'descending',
    asc = 'ascending',
}

export interface Paginator {
    totalItems: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    direction: PaginationDirection;
    changedDirection: boolean;
}

export const columnPrefixIconConfig = (icon: AppIcon, bgColor?: string): AppIcon => ({
    ...icon, fontSize: '20px', bgCircle: { bgColor: bgColor ?? css.colors.BLACK, diameter: '35px' },
});